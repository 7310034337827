


















































import { Component, Prop, Vue } from 'vue-property-decorator'
import { RoomModel } from '../models/RoomModel'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

import BaseButton from '@/components/base/BaseButton.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'

@Component({
  name: 'Room',
  components: { Swiper, SwiperSlide, BaseButton, BaseIcon },
})
export default class Room extends Vue {
  @Prop({ required: true })
  room!: RoomModel

  @Prop({ required: true })
  url!: string

  swiperOptions = {
    slidesPerView: 'auto',
    pagination: {
      el: '.Room__pagination',
      clickable: true,
    },
  }
}
